import React from "react"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import { Controller, Scene } from "react-scrollmagic"
import { Tween } from "react-gsap"
import Img from "gatsby-image"
import Logo from "../../images/svgs/logo.svg"
import Vector21 from "../../images/svgs/vector21AboutHeader2.svg"
import Vector21v2 from "../../images/svgs/vector21AboutHeader4.svg"
import Vector24 from "../../images/svgs/vector24AboutHeader1.svg"
import Vector17 from "../../images/svgs/vector17AboutHeader3.svg"
import Title from "../common/title"
import { Container } from "../global"

const Header = ({ intl }) => {
  const images = useStaticQuery(graphql`
    query {
      AllPostImages: allFile(
        filter: {
          relativeDirectory: { eq: "photosAbout" }
          extension: { eq: "png" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 508) {
                originalName
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <StyledContainer>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>

        <HeaderSections>
          <TitleBlock>
            <Title
              hType="h1"
              noMargin
              fontSize="xxlarge"
              fontSizeDesktop="xxxlarge"
              text={intl.formatMessage({ id: "h1-title-about" })}
            />
          </TitleBlock>
          <ImagesWrapper id="trigger">
            <Controller>
              <Scene triggerElement="#trigger" duration={2900}>
                {progress => (
                  <div>
                    <Tween
                      to={{
                        y: "-60%",
                      }}
                      ease="Strong.easeOut"
                      totalProgress={progress}
                      paused
                    >
                      <WrapperPhoto>
                        <StyledPhoto
                          fluid={
                            images.AllPostImages.edges[0].node.childImageSharp
                              .fluid
                          }
                        />
                      </WrapperPhoto>
                    </Tween>
                    <Tween
                      to={{
                        y: "60%",
                      }}
                      totalProgress={progress}
                      paused
                    >
                      <WrapperPhoto2>
                        <StyledPhoto2
                          fluid={
                            images.AllPostImages.edges[1].node.childImageSharp
                              .fluid
                          }
                        />
                      </WrapperPhoto2>
                    </Tween>
                    <Tween
                      to={{
                        y: "-60%",
                      }}
                      ease="Strong.easeOut"
                      totalProgress={progress}
                      paused
                    >
                      <WrapperPhoto3>
                        <StyledPhoto3
                          fluid={
                            images.AllPostImages.edges[2].node.childImageSharp
                              .fluid
                          }
                        />
                      </WrapperPhoto3>
                    </Tween>
                  </div>
                )}
              </Scene>
            </Controller>
            <div className="vector17">
              <Vector17 />
            </div>
            <div className="vector21">
              <Vector21 />
            </div>
            <div className="Vector21v2">
              <Vector21v2 />
            </div>
            <div className="vector24">
              <Vector24 />
            </div>
          </ImagesWrapper>
        </HeaderSections>
      </StyledContainer>
    </HeaderWrapper>
  )
}

export default injectIntl(Header)

const StyledContainer = styled(Container)`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 24px;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 0 40px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    padding: 0 155px;
  }
`

const HeaderWrapper = styled.header`
  margin-top: 55px;
  padding-bottom: 10px;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding-bottom: 100px;
  }
`

const LogoWrapper = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const HeaderSections = styled.div`
  margin-top: 85px;

  p {
    margin-top: 32px;
    ${({ theme }) => theme.font_size.medium};
    font-family: ${({ theme }) => theme.font.medium};
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-top: 145px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin-top: 85px;
  }
`

const TitleBlock = styled.div`
  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 450px;
    margin-top: 20%;
  }
`

const ImagesWrapper = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  padding-top: 114px;
  position: relative;

  .tween {
    width: 100px;
    height: 100px;
    background-color: red;
    margin: 0 !important;
    position: relative;
    left: calc(50% - 50px);
  }

  .vector21 {
    position: absolute;
    top: 455px;
    z-index: 6;

    @media (min-width: ${props => props.theme.screen.md}) {
      display: none;
    }
  }

  .vector24 {
    top: 0;
    position: absolute;
    z-index: 2;

    @media (min-width: ${props => props.theme.screen.md}) {
      display: none;
    }
  }

  .vector17 {
    display: none;

    @media (min-width: ${props => props.theme.screen.md}) {
      display: block;
      top: 0;
      position: absolute;
      z-index: 2;
      right: 0;
      margin-top: -55px;

      svg {
        width: 634px;
        height: 639px;
      }
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      display: block;
      top: 0;
      position: absolute;
      z-index: 2;
      right: 0;
      margin-top: -55px;

      svg {
        width: 1040px;
        height: 1048px;
      }
    }
  }

  .Vector21v2 {
    display: none;

    @media (min-width: ${props => props.theme.screen.md}) {
      display: block;
      position: absolute;
      z-index: 10;
      top: 480px;

      svg {
        width: 422px;
        height: 324px;
      }
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      top: 700px;

      svg {
        width: 595px;
        height: 457px;
      }
    }
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-left: -40px;
    margin-right: -40px;
    padding-top: 114px;
    position: absolute;
    width: 1024px;
    top: 0;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin-left: -155px;
    margin-right: -155px;
    padding-top: 114px;
    position: absolute;
    width: 1440px;
    top: 0;
  }
`
const WrapperPhoto = styled.div`
  width: 100%;
  position: relative;
  z-index: 5;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: flex;
    justify-content: flex-end;
    padding-right: 162px;
    padding-top: 160px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    padding-right: 202px;
    padding-top: 280px;
  }
`

const StyledPhoto = styled(Img)`
  width: 184px;
  margin-left: 51px;

  @media (min-width: ${props => props.theme.screen.md}) {
    width: 310px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    width: 508px;
  }
`

const WrapperPhoto2 = styled.div`
  width: 100%;
  position: relative;
  z-index: 5;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 380px;
    right: 0px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    top: 580px;
    right: -120px;
  }
`

const StyledPhoto2 = styled(Img)`
  width: 135px;
  z-index: 5;
  margin-left: 208px;
  margin-top: -40px;

  @media (min-width: ${props => props.theme.screen.md}) {
    width: 235px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    width: 386px;
  }
`

const WrapperPhoto3 = styled.div`
  width: 100%;
  position: relative;
  z-index: 5;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: flex;
    position: absolute;
    top: 790px;
    left: 20px;
    width: calc(100% - 20px);
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    top: 1020px;
  }
`

const StyledPhoto3 = styled(Img)`
  width: 185px;
  z-index: 5;
  margin-top: 155px;
  margin-left: 17px;

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-top: 55px;
    width: 312px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    width: 440px;
  }
`
