import React from "react"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"
import Vector22 from "../../images/svgs/vector22AboutBody1.svg"
import Title from "../common/title"
import { Container } from "../global"

const Body = ({ intl }) => {
  const images = useStaticQuery(graphql`
    query {
      image1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "body_about_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 555) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      image2: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "body_about_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 555) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  return (
    <VisibilitySensor
      partialVisibility="bottom"
      offset={{ bottom: -550 }}
      onChange={e => {
        if (e) {
          document.getElementById("LogoDurcal").classList.add("white")
        } else {
          document.getElementById("LogoDurcal").classList.remove("white")
        }
      }}
    >
      <BodyWrapper>
        <div className="vector22">
          <Vector22 />
        </div>
        <StyledContainer>
          <BodySection>
            <div className="bodySection_text">
              <Title
                hType="h3"
                color="white"
                noMargin
                fontSize="xlarge"
                fontSizeDesktop="xxlarge"
                text={intl.formatMessage({ id: "h2-title-body-1" })}
              />
              <p>{intl.formatMessage({ id: "p-body-1-1" })}</p>
            </div>
            <StyledPhoto fluid={images.image1.childImageSharp.fluid} />
          </BodySection>
          <BodySection reverse>
            <div className="bodySection_text">
              <Title
                hType="h3"
                color="white"
                noMargin
                fontSize="xlarge"
                fontSizeDesktop="xxlarge"
                text={intl.formatMessage({ id: "h2-title-body-2" })}
              />
              <p>{intl.formatMessage({ id: "p-body-2" })}</p>
              <p className="caption-about-footer">
                {intl.formatMessage({ id: "caption-about-footer" })}
              </p>
            </div>
            <StyledPhoto fluid={images.image2.childImageSharp.fluid} />
          </BodySection>
        </StyledContainer>
      </BodyWrapper>
    </VisibilitySensor>
  )
}

export default injectIntl(Body)

const StyledContainer = styled(Container)`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  padding: 0 24px;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 0 40px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    padding: 0 155px;
  }
`

const BodyWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.purpleLight};
  padding-top: 80px;
  padding-bottom: 40px;

  .vector22 {
    display: none;
  }

  p {
    ${({ theme }) => theme.font_size.regular};
    color: ${({ theme }) => theme.color.white};
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-top: 380px;
    padding-top: 220px;
    padding-bottom: 80px;

    .vector22 {
      position: absolute;
      /* display: block; */
      top: 350px;
      left: -135px;

      svg {
        width: 715px;
        height: 309px;
      }
    }
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin-top: 660px;

    .vector22 {
      top: 430px;
      left: 95px;

      svg {
        width: 957px;
        height: 413px;
      }
    }
  }
`

const BodySection = styled.div`
  margin-bottom: 120px;

  .caption-about-footer {
    color: ${({ theme }) => theme.color.purpleDeep};
    text-transform: uppercase;
    ${({ theme }) => theme.font_size.small};
    letter-spacing: 4px;
    font-family: ${({ theme }) => theme.font.extraBold};
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .bodySection_text {
      max-width: 382px;
    }

    ${({ reverse }) => reverse && "flex-direction: row-reverse;"}
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    .bodySection_text {
      max-width: 440px;
    }
  }
`

const StyledPhoto = styled(Img)`
  width: 328px;
  margin-top: 32px;

  @media (min-width: ${props => props.theme.screen.md}) {
    width: 462px;
    margin-top: 0;
    z-index: 5;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    width: 555px;
  }
`
