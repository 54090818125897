import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Header from "../components/about/header"
import Body from "../components/about/body"

const About = ({ location, intl }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_about" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_about" })

  return (
    <Layout {...{ location }}>
      <SEO title={SEOTitle} description={SEODescription} />
      <Header />
      <Body />
    </Layout>
  )
}

export default injectIntl(About)
